.contacts__container{
    width: 100%;
    height: fit-content;
    background: #f29400;
    padding-bottom: 50px;
}
.contacts__container h1{
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 60px;
}
.map__container{
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.contacts__container-map{
    border-radius: 12px;
}
.map__container-text{
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    gap: 20px;
    white-space: nowrap;
}
.address{
    text-align: center;
    margin-top: 30px;
    font-size: 30px;
    text-decoration: underline;
}


@media (max-width: 320px) {
    .contacts__container-map{
        width: 220px;
    }
}