.card__container{
    width: 100%;
    height: fit-content;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}
.card__container h1{
    font-size: 60px;
    padding-top: 20px;
    padding-bottom: 70px;
}
.card__container-document{
    height: 650px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 60px;
    border-radius: 6px;
}
.card__container-content{
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.card__container-btn{
    border: 2px solid black;
    text-align: center;
    border-radius: 7px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: black;
    font-size: 30px;
}
.card__container-btn:hover{
    cursor: pointer;
    background: black;
    color: white;
    transition: all 0.3s ease-in-out;
}



@media (max-width: 900px){
    .card__container-content h1{
        font-size: 40px;
    }
}

@media (max-width: 620px){
    .card__container-content h1{
        text-align: center;
    }
}