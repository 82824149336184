.footer__container{
    width: 100%;
    height: 100%;
    background: #f29400;
    display: flex;
    justify-content: center;
    height: 250px;
}
.footer__logo{
    width: 150px;
    height: 150px;
    padding: 50px;
    padding-right: 20px;
}
.footer__container-author{
    width: 100%;
    margin-top: 60px;
}
.footer__container-info{
    padding: 40px;
    font-size: 20px;
}
.footer__container-author p{
    font-size: 30px;
}
.footer__container-author h1{
    font-size: 20px;
}



@media (max-width: 510px){
   .footer__logo{
        padding: 0;
   }
   .footer__logo{
        padding-top: 30px;
    }
   .footer__container-info{
        padding: 0;
   }
   .footer__container-info{
        padding-left: 30px;
    }
}

@media (max-width: 400px){
    .footer__logo{
         padding-top: 35px;
    }
    .footer__container-info{
         padding-left: 10px;
    }
 }

@media (max-width: 350px){
    .footer__logo{
         padding-top: 30px;
    }
    .footer__container-info{
         padding-left: 10px;
    }
    .footer__container-author p{
        font-size: 26px;
    }
}

@media (max-width: 350px){
    .footer__logo{
         padding-top: 40px;
    }
}

@media (max-width: 260px){
    .footer__container-author p{
        font-size: 12px;
    }
}