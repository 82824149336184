/* ВСТУПИТЕЛЬНЫЙ БЛОК */
.about__container{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 100px;
    white-space: nowrap;
}
.about__container-logo{
    width: 200px;
    height: 200px;
}
.about__container-text{
    width: fit-content;
    height: fit-content;
    text-align: center;
}
.about__container-text h1{
    font-size: 45px;
}
.about__container-text p{
    font-size: 20px;
}


/* ГРАДИЕНТЫ */
.about__container-top-dragient{
    width: 100%;
    height: 50px;
    background: linear-gradient(180deg, rgba(0,0,0,0.6474964985994398) 0%, rgba(255,255,255,0) 100%);
}
.about__container-bottom-dragient{
    width: 100%;
    height: 50px;
    background: linear-gradient(0deg, rgba(0,0,0,0.6474964985994398) 0%, rgba(255,255,255,0) 100%);
}


@media (max-width: 450px) {
    .about__container-text h1{
        font-size: 30px;
    }
    .about__container-text p{
        font-size: 15px;
    }
    .about__container-logo{
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 306px) {
    .about__container-text h1{
        font-size: 30px;
    }
    .about__container-text p{
        font-size: 12px;
    }
    .about__container-logo{
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 316px) {
    .about__container-text p{
        font-size: 12px;
    }
}