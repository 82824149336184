@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

*{
    margin: 0;
    padding: 0;
    border: none;
    text-decoration: none;
}

body{
    font-family: "Roboto Condensed", sans-serif;
} 